import { debounce, forEach, groupBy, isEqual, uniq, uniqueId } from 'lodash';
import { RatingState } from '~commons/common-types';
import { isDefined, unreachable } from '../ts-utils';

/*
 * In the general case we can have may RatingsSummary & Reviews components on
 * the page. This store is a solution for 2 problems:
 *
 * 1. We don't want to fetch Rating for each RatingSummary component as there
 * might be many of them on the page (like product list). So we do do batch fetching for them
 *
 * 2. If there is review component on the page we want the RatingSummary
 * component to be controlled by corresponding Reviews component, because it not
 * only fetches rating with its data - it can also change.
 *
 */

export type SharedAppStateStore = {
  registerRatingsSummary: RegisterRatingSummaryComponent;
  registerReviews: RegisterReviewsComponent;
};

type RegisterRatingSummaryComponent = (args: {
  namespace: string;
  resourceId: string;
  onChange: (state: RatingState) => void;
  state?: RatingStateInternal;
}) => RatingsSummaryBinding;

export type RatingsSummaryBinding = {
  unregister: () => void;
  reconfigure: (args: { resourceId: string; namespace: string }) => void;
  getState: () => RatingState;
};

type RegisterReviewsComponent = (args: {
  namespace: string;
  resourceId: string;
  focusRoot: () => void;
}) => ReviewsBinding;

export type ReviewsBinding = {
  unregister: () => void;
  reconfigure: (args: { resourceId: string; namespace: string }) => void;
  setState: (s: RatingState) => void;
};

export type BatchFetchData = { [resourceId: string]: { overall: number; totalReviews: number } };

export type BatchFetch = (args: {
  namespace: string;
  resourceIds: string[];
}) => Promise<{ type: 'OK'; data: BatchFetchData } | { type: 'ERROR' }>;

export type RatingStateInternal =
  | { type: 'IDLE' }
  | { type: 'PENDING'; fetcher: 'global'; requestId: string }
  | { type: 'PENDING'; fetcher: 'component' }
  | { type: 'ERROR' }
  | { type: 'READY'; overall: number; totalReviews: number };

const toRatingState = (
  state: RatingStateInternal,
  setters: ResourceState['setters'],
): RatingState => {
  switch (state.type) {
    case 'IDLE':
    case 'ERROR':
      return state;
    case 'PENDING':
      return { type: 'PENDING' };
    case 'READY': {
      return state.totalReviews === 0
        ? { type: 'READY_EMPTY' }
        : { ...state, focusReviewsComponent: setters[0]?.focusRoot };
    }
    default:
      throw unreachable(state);
  }
};

const fromRatingState = (state: RatingState): RatingStateInternal => {
  switch (state.type) {
    case 'IDLE':
    case 'READY':
    case 'ERROR':
      return state;
    case 'PENDING':
      return { type: 'PENDING', fetcher: 'component' };
    case 'READY_EMPTY': {
      return { type: 'READY', overall: 0, totalReviews: 0 };
    }
    default:
      throw unreachable(state);
  }
};

type ResourceState = {
  namespace: string;
  resourceId: string;
  // Wix Reviews components which can set current state.
  setters: {
    id: string;
    // This function is used to focus WixReviews component
    focusRoot: () => void;
  }[];
  // WixRatingsSummary components which subscribed to current state changes
  listeners: {
    // locally generated id
    id: string;
    callback: (state: RatingStateInternal, setters: ResourceState['setters']) => void;
  }[];
  rating: RatingStateInternal;
};

const createInternalState = (batchFetch: BatchFetch) => {
  let state: ResourceState[] = [];

  // To prevent overinvoking listeners we keep the last invocation arguments
  const lastInvocationArgs = new WeakMap<
    Function,
    { rating: RatingStateInternal; setters: ResourceState['setters'] }
  >();
  const invokeListeners = (newState: ResourceState[], oldState: ResourceState[]) => {
    newState.forEach((resource) => {
      if (resource.listeners.length === 0) {
        return;
      }
      if (oldState.includes(resource)) {
        return;
      }
      resource.listeners.forEach((listener) => {
        // Prevent invoking with the same state
        if (
          isEqual(lastInvocationArgs.get(listener.callback), {
            rating: resource.rating,
            setters: resource.setters,
          })
        ) {
          return;
        }
        listener.callback(resource.rating, resource.setters);
        lastInvocationArgs.set(listener.callback, {
          rating: resource.rating,
          setters: resource.setters,
        });
      });
    });
  };

  const scheduleFetch = createFetchScheduler({ batchFetch, getState, updateState });
  function updateState(f: (s: ResourceState[]) => ResourceState[]) {
    const newState = f(state);
    const oldState = state;
    state = newState;
    scheduleFetch();
    invokeListeners(newState, oldState);
  }
  function getState() {
    return state;
  }
  return { updateState, getState };
};

const createFetchScheduler = ({
  batchFetch,
  getState,
  updateState,
}: {
  batchFetch: BatchFetch;
  getState: () => ResourceState[];
  updateState: (f: (s: ResourceState[]) => ResourceState[]) => void;
}) =>
  debounce(() => {
    const byNamespace = groupBy(getState(), 'namespace');
    const requestId = uniqueId();

    forEach(byNamespace, (items, namespace) => {
      const itemsToFetch = items.filter((i) => i.rating.type === 'IDLE' && i.setters.length === 0);
      const resourceIdsToFetch = itemsToFetch.map((i) => i.resourceId);
      if (resourceIdsToFetch.length === 0) {
        return;
      }
      updateState((state) =>
        state.map((i) => {
          if (i.namespace === namespace && resourceIdsToFetch.includes(i.resourceId)) {
            return {
              ...i,
              rating: { type: 'PENDING' as const, fetcher: 'global' as const, requestId },
            };
          }
          return i;
        }),
      );
      batchFetch({
        namespace,
        resourceIds: uniq(resourceIdsToFetch),
      }).then((result) => {
        const updates: ResourceState[] = itemsToFetch.map((item) => {
          if (result.type === 'ERROR') {
            return { ...item, rating: { type: 'ERROR' } };
          }
          const itemResult = result.data[item.resourceId];
          if (itemResult) {
            return {
              ...item,
              rating: {
                type: 'READY',
                overall: itemResult.overall,
                totalReviews: itemResult.totalReviews,
              },
            };
          }
          return { ...item, rating: { type: 'ERROR' } };
        });

        updateState((state) =>
          state.map((i) => {
            const updatedItem = updates.find(
              (u) => u.namespace === i.namespace && u.resourceId === i.resourceId,
            );
            return updatedItem ? updatedItem : i;
          }),
        );
      });
    });
  }, 1);

export const initSharedAppStateStore = ({
  batchFetch,
}: {
  batchFetch: BatchFetch;
}): SharedAppStateStore => {
  // Internal state management of all the resources
  const { updateState, getState } = createInternalState(batchFetch);

  return {
    registerRatingsSummary: (args) => {
      const listenerId = uniqueId('l');

      const callback = (s: RatingStateInternal, setters: ResourceState['setters']) =>
        args.onChange(toRatingState(s, setters));

      updateState((state) =>
        addListener(state, {
          listenerId,
          callback,
          defaultState: args.state || { type: 'IDLE' },
          namespace: args.namespace,
          resourceId: args.resourceId,
        }),
      );

      return {
        unregister: () => {
          updateState((state) => removeListener(state, listenerId));
        },
        reconfigure: ({ resourceId: newResourceId, namespace: newNamespace }) => {
          // remove listener and if there are no more listeners remove item too
          updateState((state) => {
            const stateWithoutListener = removeListener(state, listenerId);
            return addListener(stateWithoutListener, {
              listenerId,
              callback,
              defaultState: { type: 'IDLE' },
              namespace: newNamespace,
              resourceId: newResourceId,
            });
          });
        },
        getState: () => {
          const item = getState().find((i) => i.listeners.find((l) => l.id === listenerId));
          if (!item) {
            throw new Error(
              'State for RatingsSummary not found in shared state, was it unregistered?',
            );
          }
          return toRatingState(item.rating, item.setters);
        },
      };
    },
    registerReviews: ({ namespace, resourceId, focusRoot }) => {
      const setterId = uniqueId('s');
      updateState((state) =>
        addSetter(state, {
          setterId,
          namespace,
          resourceId,
          focusRoot,
        }),
      );
      return {
        unregister: () => {
          updateState((state) => removeSetter(state, setterId));
        },
        reconfigure: ({ resourceId: newResourceId, namespace: newNamespace }) => {
          // remove listener and if there are no more listeners remove item too
          updateState((state) => {
            const stateWithoutSetter = removeSetter(state, setterId);

            const stateWithListener = addSetter(stateWithoutSetter, {
              setterId,
              focusRoot,
              namespace: newNamespace,
              resourceId: newResourceId,
            });
            return stateWithListener;
          });
        },
        setState: (ratingState) => {
          const resourceState = getState().find((state) =>
            state.setters.find((s) => s.id === setterId),
          );
          if (!resourceState) {
            throw new Error('State for Reviews not found in shared state, was it unregistered?');
          }
          const originalRatingState = resourceState.rating;
          const proposedRatingState = fromRatingState(ratingState);
          if (proposedRatingState.type === 'READY' || originalRatingState.type !== 'READY') {
            const newResourceState = { ...resourceState, rating: fromRatingState(ratingState) };
            updateState((state) => state.map((s) => (s === resourceState ? newResourceState : s)));
          }
        },
      };
    },
  };
};

const addSetter = (
  state: ResourceState[],
  {
    namespace,
    resourceId,
    setterId,
    focusRoot,
  }: { namespace: string; resourceId: string; setterId: string; focusRoot: () => void },
): ResourceState[] => {
  const resourceState = state.find((s) => s.namespace === namespace && s.resourceId === resourceId);
  if (resourceState) {
    return state.map((s) => {
      if (s.namespace === namespace && s.resourceId === resourceId) {
        return {
          ...s,
          setters: [...s.setters, { id: setterId, focusRoot }],
        };
      }
      return s;
    });
  }
  return [
    ...state,
    {
      namespace,
      resourceId,
      rating: { type: 'IDLE' },
      listeners: [],
      setters: [{ id: setterId, focusRoot }],
    },
  ];
};

const addListener = (
  state: ResourceState[],
  {
    listenerId,
    callback,
    defaultState,
    namespace,
    resourceId,
  }: {
    listenerId: string;
    callback: (state: RatingStateInternal, setters: ResourceState['setters']) => void;
    defaultState: RatingStateInternal;
    namespace: string;
    resourceId: string;
  },
): ResourceState[] => {
  const existingItem = state.find((i) => i.namespace === namespace && i.resourceId === resourceId);
  // If item exits we just add a listener to existing item and return existing state for that item
  if (existingItem) {
    return state.map((i) => {
      if (i !== existingItem) {
        return i;
      }
      return {
        ...existingItem,
        listeners: [...existingItem.listeners, { id: listenerId, callback }],
      };
    });
  } else {
    // Otherwise we create a new item
    return [
      ...state,
      {
        namespace,
        resourceId,
        rating: defaultState,
        listeners: [{ id: listenerId, callback }],
        setters: [],
      },
    ];
  }
};

const removeSetter = (state: ResourceState[], setterId: string): ResourceState[] =>
  state
    .map((i) => {
      if (i.setters.find((s) => s.id === setterId)) {
        const setters = i.setters.filter((s) => s.id !== setterId);
        if (setters.length === 0 && i.listeners.length === 0) {
          return undefined;
        }
        return { ...i, setters };
      }
      return i;
    })
    .filter(isDefined);

const removeListener = (state: ResourceState[], listenerId: string): ResourceState[] =>
  state
    .map((i) => {
      if (i.listeners.find((l) => l.id === listenerId)) {
        const listeners = i.listeners.filter((l) => l.id !== listenerId);
        if (listeners.length === 0 && i.setters.length === 0) {
          return undefined;
        }
        return { ...i, listeners };
      }
      return i;
    })
    .filter(isDefined);
